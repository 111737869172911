:root {
  --back-color: #f39c12;
  --back-color-gray: #f1ecec;
  --border-color: #a3860f;
  --font: adobe-clean, sans-serif;
  --sec-controls-width: 300px;
  --main-bg: #80bfff;
  --main-bg-transparent: #b3e0ff;
  --main-bg-gray: #bec1c3;
  --main-bg-gray-light: #ebeff3;
  --main-bg-gray-light_1: #5078a0;
  --btn-back-blue: #002147;
  --focus-border-color: #e83023 !important;
}

body {
  user-select: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 100;
  background-color: #aab3b7;
}

/* klasa sa glavnom bojom */
.main-bg {
  /* background-color: var(--main-bg-gray); */
  background-color: var(--main-bg-gray-light_1);
}

/* transparentna boja*/
.main-bg-tp {
  background-color: var(--main-bg-gray-light);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 2px !important;
}

.nav-link {
  color: white;
}

.nav-link:hover {
  color: white;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--focus-border-color);
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 3px;
  font-weight: 600;
}

.form-select {
  display: block;
  border-radius: 2px !important;
  box-shadow: none;
  border-color: #d2d6de;
}
.form-select:focus {
  box-shadow: none;
  border-color: var(--focus-border-color);
}

.font-medium-upper {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: medium;
  font-weight: bold;
}

.my-textarea {
  resize: none;
}

.top-header {
  margin-left: auto;
  width: 100%;
  height: 70px;
  border: 1px solid #95979c;
  box-shadow: 0 3px 5px #0000004d;
  border-radius: 2px;
  background: #eff1f1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding: 8px;
}

.wells {
  display: flex;
  top: 0;
  left: 0;
  overflow: auto;
  height: 100%;
  width: 100%;
  border: 2px solid #7f8cb8;
  box-shadow: 0 3px 5px #0000004d;
  border-radius: 2px;
  background: #cae3f1;
  padding: 5px;
  position: inherit;
}

.price-wells {
  display: block;
  min-height: 30%;
  border: 2px solid #b8b4b4;
  box-shadow: 0 3px 5px #0000004d;
  border-radius: 2px;
}

.col-margin-left {
  margin-left: 15px;
}

.x-button {
  margin-top: 10px;
  height: 80px;
  letter-spacing: 1px;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  width: 80%;
  max-height: auto;
  word-wrap: break-word;
  background-color: #fefefe;
  background-clip: border-box;
  border: 4px solid rgba(9, 9, 9, 0.125);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
}

.my-card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 2px;
  overflow: hidden;
  margin: 2%;
  font-size: 1.3vh;
}

.my-card-header {
  padding: 15px 50px;
  text-align: left;
  font-weight: bolder;
  font-size: 1.5em;
  border-bottom: 0.5px solid #d3cdcd;
}

.my-card-content {
  height: 250px;
  overflow: auto;
}

.my-card-footer {
  padding: 10px;
  height: 80px;
  margin-top: 5px;
  text-align: left;
  border-top: 0.5px solid gray;
  font-weight: bolder;
  font-size: large;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 3px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 5px;
}

.btn:disabled {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 3px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 5px;
}

.modal-overlay {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(116, 113, 113, 0.4);
  transition: opacity 0.4s ease;
}

.my-modal-dialog {
  position: fixed;
  display: flex;
  width: auto;
}

.my-modal-dialog-two{
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(116, 113, 113, 0.4);
  transition: opacity 0.4s ease;
}

.react-datepicker-popper {
  z-index: 100 !important;
}


.react-datepicker__day--selected {
  background: #1c80a4 !important;
}
