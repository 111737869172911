/*
 version 1.0.2;
*/

:root {
  --button_color: #c9032e;
  --button_hover: #ff337e;
}

body {
  background: #76b852; /* fallback for old browsers */
  background: rgb(141, 194, 111);
  background: linear-gradient(
    90deg,
    rgb(190, 191, 189) 0%,
    rgb(195, 197, 194) 50%
  );
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.display-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgb(62, 62, 62);
  transition: opacity 500ms;
  opacity: 1;
}

.login-page {
  position: absolute;
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: fixed;
  z-index: 2000;
  background: #ffffff;
  max-width: 360px;
  left: 40%;
  top: 20%;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  color: black;
}
.form button {
  font-family: sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: var(--button_color);
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,
.form button:active,
.form button:focus {
  background: var(--button_hover);
}

.error {
  color: red;
}
.success {
  color: #76b852;
}
